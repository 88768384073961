import React, { useEffect, useState, useContext } from 'react';
import Helmet from 'react-helmet';

import Layout from '../../components/layout';

import PAGE_HEADER_ITEMS from './data/interest-free-header.json';
import CarouselHeader from '@/components/Carousel/carouselHeader';
import AreYouAMerchantSection from './_areYouAMerchant';
import HereToHelpSection from './_hereToHelp';
import FindByCategorySection from './_findByCategory';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';
import '@/components/PageLayout/pageHeader.css';

const ANY_VALUE = '*';

function FindByCategoryPage(props) {
  const [categoryName, setCategoryName] = useState(ANY_VALUE);

  useEffect(() => {
    let result = ANY_VALUE;
    if (window?.location?.search) {
      const queryParameters = new URLSearchParams(window.location.search);
      const name = queryParameters.get('c');
      if (name) {
        result = name;
      }
    }
    setCategoryName(result);
  }, []);

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={props.location}>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.latitudefinancial.com.au/interest-free/find-by-category/"
        />
        <title>Latitude Interest Free | Find by Category</title>
        <meta name="description" content="Find an Interest-Free offer." />
      </Helmet>

      <main className="navigation-spacer" css="background-color: #f8f8f8;">
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <CarouselHeader HEADER_ITEMS={PAGE_HEADER_ITEMS} />
        )}

        <FindByCategorySection
          id="find-an-offer"
          currentCategory={categoryName}
        />
        <AreYouAMerchantSection id="become-a-merchant" />
        <HereToHelpSection id="here-to-help" />
      </main>
    </Layout>
  );
}

export default FindByCategoryPage;
